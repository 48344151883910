import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { bidManagerRequest, eightBallRequest, eightBall2Request, graphRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { EightBallData } from "./components/EightBallData";
import { BidManagerData } from "./components/BidManagerData";
import { callMsGraph } from "./graph";
import { callMagicEightBall } from "./eightball";
import { callMagicEightBall2 } from "./eightball2";
import { callBidManager, callPingBidManager, callPingDbBidManager } from "./bidmanager";
import Button from "react-bootstrap/Button";
import "./styles/App.css";

const BidManagerContent = () => {
    const { instance, accounts } = useMsal();
    const [bidManagerData, setBidManagerData] = useState(null);

    function RequestBidManagerData() {
        // Silently acquires an access token which is then attached to a request
        instance.acquireTokenSilent({
            ...bidManagerRequest,
            account: accounts[0]
        }).then((response) => {
            callBidManager(response.accessToken).then(response => setBidManagerData(response));
        });
    }

    return (
        <>
            <h5>Bid Manager</h5>
            <Button variant="secondary" onClick={RequestBidManagerData}>Call Bid Manager</Button>
            {bidManagerData && <BidManagerData bidManagerData={bidManagerData} /> }
        </>
    );
};

const BidManagerPingContent = () => {
    const { instance, accounts } = useMsal();
    const [bidManagerData, setBidManagerData] = useState(null);

    function RequestBidManagerPingData() {
        // Silently acquires an access token which is then attached to a request
        instance.acquireTokenSilent({
            ...bidManagerRequest,
            account: accounts[0]
        }).then((response) => {
            callPingBidManager(response.accessToken).then(response => setBidManagerData(response));
        });
    }

    return (
        <>
            <h5>Ping Bid Manager</h5>
            <Button variant="secondary" onClick={RequestBidManagerPingData}>Ping Bid Manager</Button>
            {bidManagerData && <BidManagerData bidManagerData={bidManagerData} /> }
        </>
    );
};

const BidManagerPingDbContent = () => {
    const { instance, accounts } = useMsal();
    const [bidManagerData, setBidManagerData] = useState(null);

    function RequestBidManagerPingDbData() {
        // Silently acquires an access token which is then attached to a request
        instance.acquireTokenSilent({
            ...bidManagerRequest,
            account: accounts[0]
        }).then((response) => {
            callPingDbBidManager(response.accessToken).then(response => setBidManagerData(response));
        });
    }

    return (
        <>
            <h5>Ping Bid Manager DB</h5>
            <Button variant="secondary" onClick={RequestBidManagerPingDbData}>Ping Bid Manager DB</Button>
            {bidManagerData && <BidManagerData bidManagerData={bidManagerData} /> }
        </>
    );
};

const EightBallContent = () => {
    const { instance, accounts } = useMsal();
    const [eightBallData, setEightBallData] = useState(null);

    function RequestEightBallData() {
        // Silently acquires an access token which is then attached to a request
        instance.acquireTokenSilent({
            ...eightBallRequest,
            account: accounts[0]
        }).then((response) => {
            callMagicEightBall(response.accessToken).then(response => setEightBallData(response));
        });
    }

    return (
        <>
            <h5>Magic 8-Ball</h5>
            <Button variant="secondary" onClick={RequestEightBallData}>Ask the Magic 8-Ball</Button>
            {eightBallData && <EightBallData eightBallData={eightBallData} />}
        </>
    );
};

const EightBall2Content = () => {
    const { instance, accounts } = useMsal();
    const [eightBallData, setEightBallData] = useState(null);

    function RequestEightBall2Data() {
        // Silently acquires an access token which is then attached to a request
        instance.acquireTokenSilent({
            ...eightBall2Request,
            account: accounts[0]
        }).then((response) => {
            callMagicEightBall2(response.accessToken).then(response => setEightBallData(response));
        });
    }

    return (
        <>
            <h5>Magic 8-Ball (2)</h5>
            <Button variant="secondary" onClick={RequestEightBall2Data}>Ask the Magic 8-Ball (2)</Button>
            {eightBallData && <EightBallData eightBallData={eightBallData} />}
        </>
    );
};

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...graphRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }

    return (
        <>
            <h5 className="card-title">Welcome {accounts[0].name}</h5>
            {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
            }
        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {    
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <EightBallContent />
                <EightBall2Content />
                <BidManagerPingContent />
                <BidManagerPingDbContent />
                <BidManagerContent />
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
