import React from "react";

/**
 * Renders answer obtained from 8-Ball API
 * @param props 
 */
export const EightBallData = (props) => {
    console.log(props.eightBallData);

    return (
        <div id="eightball-div">
            <p><strong>The Magic 8-Ball Says: </strong> {props.eightBallData.answer}</p>
        </div>
    );
};