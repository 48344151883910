import { bidManagerConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callBidManager(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(bidManagerConfig.bidManagerEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
 export async function callPingBidManager(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
      method: "GET",
      headers: headers
  };

  return fetch(bidManagerConfig.bidManagerPingEndpoint, options)
      .then(response => response.json())
      .catch(error => console.log(error));
}

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
 export async function callPingDbBidManager(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
  
    headers.append("Authorization", bearer);
  
    const options = {
        method: "GET",
        headers: headers
    };
  
    return fetch(bidManagerConfig.bidManagerPingDbEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
  }
  