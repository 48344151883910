import React from "react";

/**
 * Renders answer obtained from Bid Manager API
 * @param props 
 */
export const BidManagerData = (props) => {
    console.log(props.bidManagerData);

    return (
        <div id="bidmanager-div">
            <p><strong>The Bid Manager API was successfully invoked! </strong></p>
        </div>
    );
};